<template>
  <div class="layout-container">
    <AppHeader v-if="mainStore.header"></AppHeader>
    <div>
      <slot />
    </div>
    <AppFooter v-if="mainStore.footer"></AppFooter>
    <ClientOnly>
      <DialogLogin v-if="mainStore.visibleLogin"></DialogLogin>
      <DialogSignUp v-if="mainStore.visibleSignUp"></DialogSignUp>
      <DialogForgot v-if="mainStore.visibleForgot"></DialogForgot>
      <DialogEmailLogin v-if="mainStore.visibleEmailLogin"></DialogEmailLogin>
      <DialogVipDialog v-if="mainStore.visibleVipDialog"></DialogVipDialog>
      <DialogPay v-if="mainStore.visiblePay" />
      <Cookie />
      <!-- <CustomerChat /> -->
    </ClientOnly>
    <!-- <div v-if="loading" class="fixed top-0 left-0 bottom-0 right-0 z-[999] flex items-center justify-center">
      <Loading  />
    </div> -->
    <div v-show="loading" class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <Loading  />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useMainStore } from "@/store/index";
const {
  public: { MODE },
} = useRuntimeConfig();
const mainStore = useMainStore();
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true,
});
const nuxtApp = useNuxtApp();
// console.log("client", process.client);
// qa和正式环境做上报
if (process.client && MODE !== "dev") {
  let env = "production";
  switch (MODE) {
    case "dev":
      env = "development";
      break;
    case "pre":
      env = "pre-production";
      break;
    default:
      env = "production";
      break;
  }

  window.apmPlus("init", {
    aid: 589973,
    token: "4f25c48804b747d98423a685b8db3f6d",
    release: `${config.version}.${config.build}`,
    env: env,
    plugins: {
      pageview: {
        routeMode: 'history',
        extractPid: (url: string) => {
          return new URL(url).pathname
        }
      }
    }
  });
  window.apmPlus("start");
}
const loading = ref(false);
nuxtApp.hook("page:start", () => {
  loading.value = true;
  // console.log('page:start');
  
});

nuxtApp.hook("page:finish", () => {
  setTimeout(()=>{
    loading.value = false;

  },300)
});
</script>
<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  /* filter: blur(0.5rem); */
}
</style>
<!-- <script>
import { mapState } from "vuex";
import VHeader from "~/components/header/header";
import VFooter from "~/components/home/footer";
import Cookie from "~/components/home/cookie";
import Login from "@/components/header/login";
import SignUp from "@/components/header/signUp";
import Forgot from "@/components/header/forgot";
import EmailLogin from "@/components/header/emailLogin";
import VipDialog from "@/components/header/vipDialog";
import Pay from "@/components/header/pay";
import CustomerChat from "@/components/CustomerChat";
// import { state } from "../store";

export default {
  components: {
    VHeader,
    VFooter,
    Cookie,
    Login,
    SignUp,
    EmailLogin,
    VipDialog,
    Forgot,
    Pay,
    CustomerChat
  },
  mounted() {
    if (process.env.MODE == "pro") {
      // document.addEventListener("contextmenu", (event) => {
      //   event.preventDefault();
      // });
      // 阻止F12按键的默认行为
      window.addEventListener("keydown", function (event) {
        if (event.key === "F12") {
          event.preventDefault();
        }
      });
    }
  },
  computed: {
    ...mapState({
      footer: (state) => state.layout.footer,
      visiblePay: (state) => state.visiblePay,
      visibleVipDialog:  (state) => state.visibleVipDialog,
      visibleForgot:  (state) => state.visibleForgot,
      visibleSignUp:  (state) => state.visibleSignUp,
      visibleEmailLogin:  (state) => state.visibleEmailLogin,
      visibleLogin:  (state) => state.visibleLogin,
    }),
  },
};
</script> -->
